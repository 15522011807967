import React, { memo } from 'react';

import MuiThemeProvider from '@material-ui/styles/ThemeProvider';

import { brightLightTheme, spaceGrayTheme } from '../../../theme';
import { useSelector } from '../../../hooks/useSelector';

const schemeThemeMap = {
  client_light: brightLightTheme,
  bright_light: brightLightTheme,
  client_dark: spaceGrayTheme,
  space_gray: spaceGrayTheme,
  vkcom_light: brightLightTheme,
  vkcom_dark: spaceGrayTheme,
  vkcom_gray: spaceGrayTheme,
};

/**
 * Theme provider for application
 * @type {React.NamedExoticComponent<Props>}
 */
const ThemeProvider = memo((props) => {
  const scheme = useSelector((state) => state.appConfig.scheme);
  const theme = schemeThemeMap[scheme] ? schemeThemeMap[scheme] : brightLightTheme;

  return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>;
});

export default ThemeProvider;
