import { ApolloClient, InMemoryCache } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';

interface CreateApolloClientOptions {
  httpURI: string;
  launchParams: string;
}

export function createApolloClient(options: CreateApolloClientOptions) {
  const { httpURI, launchParams } = options;
  const isNewAuthDomain = [
      'https://back.yclients.staging.kapps.vk-apps.com/graphql',
      'https://back.yclients.prod.kapps.vk-apps.com/graphql',
  ].indexOf(httpURI) !== -1;
  return new ApolloClient({
    uri: httpURI,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            organizationUnits: offsetLimitPagination(['input']),
          },
        },
      },
    }),
    headers: isNewAuthDomain ? { 'Authorization': launchParams } : { 'x-launch-params': launchParams },
  });
}
